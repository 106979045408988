<script setup>
import { onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';
import $t from "o365.modules.translate.ts";
import ODataTable from 'o365.vue.components.DataTable.vue'
import { deleteRow } from 'sja.vue.modules.utilities.js'
 
const props = defineProps({
    row: Object,
    tableType: String,
    readOnly: Boolean
});

let dataObject = null;

if (props.tableType === "Hazards") {
    dataObject = getOrCreateDataObject({
        id: `ds_TasksHazards_${props.row.ID}`,
        uniqueTable: 'atbl_SJA_SJATasksHazards',
        maxRecords: 50,
        viewName: 'aviw_SJA_SJATasksHazards',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Color', },
            { name: 'FinalColor', },
            { name: 'Matrix_ID', },
            { name: 'SJATask_ID', },
            { name: 'FinalMatrix_ID', },
            { name: 'ProbabilityNameAndDesc', },
            { name: 'ConsequenceNameAndDesc', },
            { name: 'FinalProbabilityNameAndDesc', },
            { name: 'FinalConsequenceNameAndDesc', },
            { name: 'ProbabilityValue', },
            { name: 'ConsequenceValue', },
            { name: 'FinalProbabilityValue', },
            { name: 'FinalConsequenceValue', }
        ],
        whereClause: `SJATask_ID = ${props.row.ID}`
    });
}

if (props.tableType === "Actions") {
    dataObject = getOrCreateDataObject({
        id: `ds_TasksHazardsActions_${props.row.ID}`,
        uniqueTable: 'atbl_SJA_SJATasksHazardsActions',  
        maxRecords: 50,
        viewName: 'aviw_SJA_SJATasksHazardsActions',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Comment', },
            { name: 'IsVerified', }
        ],
        whereClause: `SJATasksHazard_ID = ${props.row.ID}`
    });
}

if (props.tableType === "Responsible") { 
    dataObject = getOrCreateDataObject({
        id: `ds_TasksHazardsActionsResp_${props.row.ID}`,
        uniqueTable: 'atbl_SJA_SJATasksHazardsActionsResponsibles',
        maxRecords: 50,
        viewName: 'aviw_SJA_SJATasksHazardsActionsResponsibles',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'SJATasksHazardsAction_ID', },
            { name: 'Responsible_ID', },
            { name: 'Name', }
        ],
        whereClause: `SJATasksHazardsAction_ID = ${props.row.ID}`
    });
}

dataObject.load();

onMounted(() => {
    const footers = document.querySelectorAll('.sub-table .o365-footer');
    footers.forEach((footer) => footer.classList.add("d-none"));
})

</script>

<template>
    <div>
        <template v-if="props.tableType !== 'Responsible'">
            <ODataTable style="font-size:100%" disableColumnMove skipElementIndexing disableFilterRow hideMultiselectColumn
                hideSystemColumn hideActionColumn hideGridMenu collapseGridMenu disableNavigation class="sub-table"
                :dataObject="dataObject">
                <slot></slot>
            </ODataTable>
        </template>
        <template v-if="props.tableType === 'Responsible'" >
            <span v-for="responsible in dataObject.data" class="border mt-2 d-inline-block p-1 me-2">{{ responsible.Name }}<i v-if="!props.readOnly" type="button"
                        :title="$t('Remove person.')" class="px-2 text-black bi bi-x-lg"
                        @click="deleteRow(responsible, 'Responsible')"></i></span>
        </template>
    </div>
</template>